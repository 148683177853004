<template>
  <v-container>
    <v-row class="my-1">
      <v-btn text @click="$router.push('/debtor/to-pay')" color="primary">
        <v-icon left>mdi-arrow-left-bold</v-icon>
        <strong>{{ $t('BACK') }}</strong>
      </v-btn>
    </v-row>
    <div class="headline mb-10">
      {{ $t('CHECKOUT_TITLE') }}
    </div>
    <v-form v-model="attrs.valid" ref="form">
      <v-row v-if="basket.invoices.length">

        <v-col cols="12" md="9" class="order-2 order-md-1">
          <InvoiceTable></InvoiceTable>
        </v-col>

        <v-col cols="12" md="3" class="order-1 order-md-2">
          <CheckoutSummary
              v-bind="attrs"
              v-on="on"
          ></CheckoutSummary>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import InvoiceTable from '@/components/debtor/checkout/InvoiceTable';
import CheckoutSummary from '@/components/debtor/checkout/CheckoutSummary';

import basketService from '@/services/basketService';
import paymentOperationService from '@/services/paymentOperationService';
import userService from '@/services/userService';

import { mapState } from 'vuex';

export default {
  components: {
    InvoiceTable,
    CheckoutSummary
  },
  data () {
    return {
      attrs: {
        paymentLoading: false,
        creditor: undefined,
        valid: false
      },
      on: {
        pay: this.startPaymentProcess
      }
    };
  },
  computed: {
    ...mapState({
      basket: state => state.basket
    }),
    ...mapState(['selectedDebtorAccount', 'creditor'])
  },
  async beforeMount () {
    const { data } = await userService.getCreditorAccount(this.selectedDebtorAccount.creditor.id);
    this.attrs.creditor = data;
  },
  mounted () {
    this.$refs.form.validate();
  },
  methods: {
    async startPaymentProcess (paymentMean, directDebitDate, umr) {
      this.attrs.paymentLoading = true;
      try {
        const { data } = await paymentOperationService.createPaymentOperation({
          basket: this.basket,
          creditorId: this.attrs.creditor.id,
          debtorId: this.selectedDebtorAccount.id,
          paymentMean,
          umr,
          directDebitDate,
          languageCode: this.$t('LANG_ID')
        });
        if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        }
        if (paymentMean.type === 'SEPA_DD') {
          await this.$router.push({ name: 'DebtorTransaction', params: { id: data.transactionReference } });
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.attrs.paymentLoading = false;
      }
    }
  },
  watch: {
    'basket.invoices': {
      deep: true,
      handler () {
        if (this.$refs.form.validate()) {
          const balance = basketService.computeBasketBalance(this.basket);
          this.basket.balance = balance;
          return this.$store.dispatch('setBasket', this.basket);
        }
      }
    }
  }
};
</script>