var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.debtor)?_c('div',[_c('v-data-table',{attrs:{"light":"","hide-default-footer":"","headers":_vm.computedHeaders,"items":_vm.invoices,"disable-pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [(!_vm.oneOffMode || item.pdfFilename === undefined)?_c('span',{staticClass:"overline"},[_vm._v(_vm._s(item.number))]):_c('InvoiceNumberWithDownloadLink',{attrs:{"invoice":item,"oneOffMode":_vm.oneOffMode}})]}},{key:"item.issueDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.issueDate)))])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.dueDate)))]),(!_vm.readonly && _vm.isBeforeToday(item.dueDate))?_c('span',[_c('br'),_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-clock-alert-outline")]),_vm._v(" (+"+_vm._s(("" + (_vm.daysSince(item.dueDate)) + (_vm.$t('DAY_FIRST_LETTER'))))+") ")],1):_vm._e()]}},{key:"item.outstandingAmount",fn:function(ref){
var item = ref.item;
return [_c('BoldAndRelativeAmount',{attrs:{"amount":item.temporaryOutstandingAmount,"isInvoice":item.isInvoice,"currency":item.currency}})]}},{key:"item.paymentAmount",fn:function(ref){
var item = ref.item;
return [(!_vm.oneOffMode)?_c('v-text-field',{staticClass:"mt-4",attrs:{"type":"number","dense":"","label":_vm.$t('AMOUNT'),"suffix":_vm.$options.filters.currency(item.currency),"outlined":"","rules":[_vm.rules.positive, _vm.rules.max(item.temporaryOutstandingAmount), _vm.rules.maxDecimals(2)],"append-icon":"mdi-restore","maxlength":"5"},on:{"click:append":function($event){item.balance.amount = item.temporaryOutstandingAmount}},model:{value:(item.balance.amount),callback:function ($$v) {_vm.$set(item.balance, "amount", $$v)},expression:"item.balance.amount"}}):_c('strong',[_vm._v(_vm._s(_vm._f("currency")(item.temporaryOutstandingAmount,item.currency)))])]}},{key:"item.initialAmount",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"black--text"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.amountWithTax,item.currency))+" ")])]}},{key:"item.paidAmount",fn:function(ref){
var item = ref.item;
return [_c('BoldAndRelativeAmount',{attrs:{"amount":item.balance.amount,"isInvoice":item.isInvoice,"currency":item.currency}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('DeleteItemButton',{attrs:{"item":item},on:{"delete-item":function($event){return _vm.deleteItem(item)}}})]}}],null,false,3875017266)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }