<template>
  <div v-if="selectedDebtorAccount">
    <slot
      name="activator"
      :on="on"
      :attrs="attrs"
      :items="items"
    ></slot>
    <v-dialog v-model="dialog"
      scrollable
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          {{ $t('ADD_CREDIT_NOTES') }}
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-row no-gutters class="ma-3 text-center">
          <v-col cols="4">
            <span class="overline">{{ $t('CREDIT_NOTE_OUTSTANDING') }}</span>
            <br>
            {{ basket.balance.amount | currency(selectedDebtorAccount.currency) }}
          </v-col>
          <v-col cols="8">
            <span class="overline">{{ $t('SELECTION') }} / {{ $t('AVAILABLE_AMOUNT') }}</span>
            <br>
            <span class="green--text text--darken-4">
              {{ -totalSelection | currency(selectedDebtorAccount.currency) }}
            </span>
              /
            <span class="green--text text--darken-4">
              {{ -totalAvailable | currency(selectedDebtorAccount.currency) }}
            </span>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-card-text class="dialog-text-height">
          <v-checkbox
            v-for="item in items"
            :key="item.id"
            v-model="selected"
            :value="item"
          >
            <template v-slot:label>
              <span class="small">
                {{ item.number }} ({{ -item.temporaryOutstandingAmount | currency(selectedDebtorAccount.currency) }})
              </span>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            small
            depressed
            text
            @click.stop="toggleSelection"
          >
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <v-icon small left>{{ selected.length ? 'mdi-select' : 'mdi-select-inverse' }}</v-icon>
            <span>{{ $t(selected.length ? 'UNSELECT_ALL' : 'SELECT_ALL') }}</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('ACTION_CLOSE') }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click.stop="add"
            :loading="attrs.loading"
            :disabled="selected.length === 0"
          >
            {{ $t('ACTION_ADD') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import numberMixin from '@/mixins/numberMixin';

import invoiceService from '@/services/invoiceService';
import invoiceMapper from '@/mappers/invoiceMapper';
import basketService from '@/services/basketService';

import { mapState } from 'vuex';

export default {
  mixins: [numberMixin],
  data () {
    return {
      attrs: {
        loading: false
      },
      on: {
        click: this.open
      },
      items: [],
      selected: [],
      dialog: false,
      loading: false
    };
  },
  computed: {
    totalSelection () {
      return this.selected.map(item => item.temporaryOutstandingAmount).reduce(this.sumReducer, 0);
    },
    totalAvailable () {
      return this.items.map(item => item.temporaryOutstandingAmount).reduce(this.sumReducer, 0);
    },
    ...mapState(['selectedDebtorAccount']),
    ...mapState({
      basket: state => state.basket
    })
  },
  methods: {
    open () {
      this.dialog = true;
    },
    close () {
      this.dialog = false;
      this.attrs.loading = false;
    },
    async load () {
      if (this.basket.accountId) {
        try {
          this.attrs.loading = true;
          const { data } = await invoiceService.getUsableCreditNotes(this.basket.accountId);
          // we remove from the results the invoices that are already in the basket
          this.items = invoiceMapper
            .mapAll(data)
            .filter(invoice => !this.hasInvoice(invoice));

          this.selected = [];
        } catch (err) {
          this.handleError(err);
        } finally {
          this.attrs.loading = false;
        }
      }
    },
    hasInvoice ({ id } = {}) {
      return this.basket.invoices.some(invoice => invoice.id === id);
    },
    add () {
      this.attrs.loading = true;
      this.basket.invoices.push(...basketService.formatInvoices(this.selected));
      this.close();
    },
    toggleSelection () {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected.push(...this.items);
      }
    }
  },
  beforeMount () {
    return this.load();
  },
  watch: {
    'basket.invoices.length': {
      deep: true,
      handler () {
        return this.load();
      }
    }
  }
};
</script>

<style scoped>
.dialog-text-height {
  height: 300px;
}
</style>