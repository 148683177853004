<template>
  <v-skeleton-loader
      v-if="loading"
      type="list-item-three-line"
  ></v-skeleton-loader>
  <v-card
      outlined
      v-else-if="debtor"
  >
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="8">
          <v-icon>mdi-cart-arrow-right</v-icon>
          <span class="overline ml-1">{{ $t('SUMMARY') }}</span>
        </v-col>

        <v-col cols="4" class="text-end" align-self="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  small
                  icon
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  @click="hideDetails = !hideDetails">
                <v-icon v-if="hideDetails">mdi-chevron-left-circle</v-icon>
                <v-icon v-else>mdi-chevron-down-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ hideDetails ? $t('DETAILS_MORE') : $t('DETAILS_LESS') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pb-0">
      <div v-if="!hideDetails && balance.invoices">
        <v-row no-gutters>
          <v-col cols="12">
            <span class="overline">{{ $t('DETAILED_SITUATION') }}</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters class=" my-2">
          <v-col cols="6">
            {{ $t('TOTAL_INVOICES') }}
          </v-col>
          <v-col cols="6" class="text-end black--text">
            {{ balance.invoices.totalWithTax | currency(debtor.currency) }}
            <br>
            <small>({{ balance.invoices.totalWithoutTax | currency(debtor.currency) }} {{ $t('TAXES_EXCL') }})</small>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-2">
          <v-col cols="8">
            {{ $t('LABEL_OUTSTANDING') }}
          </v-col>
          <v-col cols="4" class="text-end black--text">
            {{ balance.invoices.totalOutstanding | currency(debtor.currency) }}
          </v-col>
        </v-row>

      </div>

      <v-row no-gutters>
        <v-col cols="8">
          <span class="overline">{{ $t('INVOICES') }} ({{ balance.invoices.quantity }})</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters class="my-2">
        <v-col cols="8">
          {{ readonly ? $t('PAID_AMOUNT') : $t('CURRENTLY_PAYING') }}
        </v-col>
        <v-col cols="4" class="text-end black--text">
          <strong>{{ balance.invoices.amount | currency(debtor.currency) }}</strong>
        </v-col>
      </v-row>

      <div v-if="!hideCreditNotes">
        <v-row no-gutters class="mt-4">
          <v-col cols="5">
            <span class="overline">
              {{ $t('CREDIT_NOTES') }} ({{ balance.creditNotes.quantity }})
            </span>
          </v-col>
          <v-col cols="7" class="text-end" align-self="center">
            <CreditNoteDialog v-if="!readonly">
              <template v-slot:activator="{ on, attrs, items }">
                <v-btn
                    v-on="on"
                    v-bind="attrs"
                    x-small
                    depressed
                    color="primary"
                    outlined
                    rounded
                    :disabled="items.length === 0"
                >
                  {{ items.length }} {{ $tc('AVAILABLE', items.length) }}
                  <v-icon right>mdi-plus</v-icon>
                </v-btn>
              </template>
            </CreditNoteDialog>
          </v-col>
        </v-row>

        <v-divider class="mb-2"></v-divider>
        <v-row no-gutters class="my-2">
          <v-col cols="8">
            {{ readonly ? $t('DEDUCTED_AMOUNT') : $t('CURRENTLY_DEDUCTING') }}
          </v-col>
          <v-col cols="4" class="text-end green--text text--darken-4">
            <strong>{{ -balance.creditNotes.amount | currency(debtor.currency) }}</strong>
          </v-col>
        </v-row>
      </div>

      <v-row no-gutters class="mt-4">
        <v-col cols="12">
          <span class="overline">{{ readonly ? $t('AMOUNT_PAID') : $t('AMOUNT_TO_PAY') }}</span>
        </v-col>
      </v-row>
      <v-divider class="mb-2"></v-divider>
      <v-row no-gutters class="my-2">
        <v-col cols="1">
          <span>=</span>
        </v-col>
        <v-col cols="11" class="text-end black--text">
          <strong>{{ balance.amount | currency(debtor.currency) }}</strong>
        </v-col>
      </v-row>

      <div v-if="!readonly && !isTemporaryStatus">
        <v-row no-gutters class="mt-4">
          <v-col cols="12">
            <div v-if="!paymentMeansLoading && isPisAvailable && !onlyOnePaymentMean" v-show="shouldDisplayHighlightedPis">
              <span class="overline custom-overline">{{ $t('RECOMMENDED_PAYMENT_MEAN') }}</span>
              <v-divider class="mt-1"></v-divider>
              <v-radio-group v-model="paymentMean" class="justify-center">
                <v-radio v-for="pisPaymentMean in pisPaymentMeans"
                    :key="pisPaymentMean.id"
                    :value="pisPaymentMean"
                    :disabled="paymentWithCreditNotesOnly"
                >
                  <template v-slot:label>
                    <i class="margin-right-8 fa-fw" :class="pisPaymentMean.icon"></i>
                    {{ $t(pisPaymentMean.i18nTag) }}
                  </template>
                </v-radio>
                <div class="margin-left-30">
                  <div class="d-flex flex-row align-items-start">
                    <v-icon left>mdi-check</v-icon>
                    <span class="recommended-mean">{{ $t('PIS_SECURITY') }}</span>
                  </div>
                  <div class="d-flex flex-row align-items-start">
                    <v-icon left>mdi-check</v-icon>
                    <span class="recommended-mean">{{ $t('PIS_EASY_TO_USE') }}</span>
                  </div>
                </div>
                <span class="overline custom-overline mt-4">{{ $t('OTHER_PAYMENT_MEAN') }}</span>
                <v-divider class="mt-1"></v-divider>
                <div class="v-input-like">
                <v-radio
                      v-for="paymentMean in pisFilteredPaymentMeans"
                      :key="paymentMean.id"
                      :value="paymentMean"
                      :disabled="paymentWithCreditNotesOnly || (paymentMean.type === 'SEPA_DD' && !mandatesLoading)"
                  >
                    <template v-slot:label>
                      <i class="margin-right-8 fa-fw" :class="paymentMean.icon"></i>
                      {{ $t(paymentMean.i18nTag) }}
                    </template>
                </v-radio>
                </div>
              </v-radio-group>
            </div>
            <div v-if="!paymentMeansLoading && !onlyOnePaymentMean" v-show="!shouldDisplayHighlightedPis">
              <span class="overline">{{ $tc('OPTIONS_PAYMENT_MEAN', availablePaymentMeans.length) }}</span>
              <v-divider></v-divider>
              <v-radio-group v-model="paymentMean" class="justify-center">
                <v-radio
                    v-for="paymentMean in availablePaymentMeans"
                    :key="paymentMean.id"
                    :value="paymentMean"
                    :disabled="paymentWithCreditNotesOnly"
                >
                  <template v-slot:label>
                    <i class="margin-right-8 fa-fw" :class="paymentMean.icon"></i>
                    {{ $t(paymentMean.i18nTag) }}
                  </template>
                </v-radio>
                <v-radio
                    v-if="paymentWithCreditNotesOnly"
                    :key=this.creditNotePaymentMean.id
                    :value=this.creditNotePaymentMean
                >
                  <template v-slot:label>
                    <v-icon left>mdi-text-box-check-outline</v-icon>
                    {{ $t('CREDIT_NOTES') }}
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions
        v-if="!readonly && !isTemporaryStatus"
        class="d-block"
    >
      <v-alert
          v-if="violatingPaymentMessage"
          type="error"
          tile
          dismissible
          outlined
          border="left"
          class="body-2"
      >
        <i18n :path="violatingPaymentMessage" tag="span">
          <template v-slot:min v-if="outOfPaymentMeanRange">
            <strong>
              {{ getMinAmount }}
            </strong>
          </template>
          <template v-slot:max v-if="outOfPaymentMeanRange">
            <strong>
              {{ getMaxAmount }}
            </strong>
          </template>
        </i18n>
      </v-alert>
      <v-btn
          block
          depressed
          tile
          class="primary"
          :disabled="!valid || violatingPaymentRules || paymentMean === undefined"
          @click.stop="startPaymentProcess"
          :loading="paymentLoading || paymentMeansLoading"
      >
        <v-icon right>mdi-cart</v-icon>
        {{ $t('ACTION_PAY') }} {{ balance.amount | currency(debtor.currency) }}
      </v-btn>
    </v-card-actions>
    <SepaPaymentDialog v-if="isSddAvailable" ref="sepaDialog" :onValidate="validateSepa" :debtor="debtor" ></SepaPaymentDialog>
  </v-card>
</template>

<script>
import numberMixin from '@/mixins/numberMixin';
import dateMixin from '@/mixins/dateMixin';
import paymentMixin from '@/mixins/paymentMixin';
import moment from 'moment-timezone';
import CreditNoteDialog from '@/components/debtor/checkout/CreditNoteDialog';
import { mapState } from 'vuex';
import ruleMixin from '@/mixins/ruleMixin';
import SepaPaymentDialog from '@/components/debtor/invoices/SepaPaymentDialog';
import paymentMeanService from '@/services/paymentMeanService';
import sepaMandateService from '@/services/sepaMandateService';

export default {
  mixins: [numberMixin, dateMixin, paymentMixin, ruleMixin],
  components: {
    SepaPaymentDialog,
    CreditNoteDialog
  },
  props: {
    valid: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isTemporaryStatus: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    paymentLoading: {
      type: Boolean,
      default: false
    },
    hideCreditNotes: {
      type: Boolean,
      default: false
    },
    debtor: {
      type: Object,
      default () {
        return this.selectedDebtorAccount;
      }
    },
    creditor: {
      type: Object,
      default: () => {
      }
    },
    oneOffMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['selectedDebtorAccount']),
    ...mapState({
      basket: state => state.basket,
      balance: state => state.basket.balance
    }),
    paymentWithCreditNotesOnly () {
      return this.balance.amount === 0;
    },
    violatingPaymentRules () {
      return this.negativePaymentAmount || this.outOfPaymentMeanRange || this.noPaymentMeansForCreditor;
    },
    negativePaymentAmount () {
      return this.balance.amount < 0;
    },
    outOfPaymentMeanRange () {
      return this.outOfCreditCardRange || this.outOfSddRange || this.outOfPisRange;
    },
    outOfCreditCardRange () {
      return this.paymentMean !== undefined && this.paymentMean.type === this.paymentMeans.CREDIT_CARD.value && (this.balance.amount < this.paymentMean.minAmount || this.balance.amount > this.paymentMean.maxAmount);
    },
    outOfPisRange () {
      // TODO : remove this.paymentMean.minAmount && this.paymentMean.maxAmount from the tests when those parameters will be mandatory for PIS
      return this.paymentMean !== undefined && this.paymentMean.type === this.paymentMeans.PIS.value && this.paymentMean.minAmount && this.paymentMean.maxAmount &&
          (this.balance.amount < this.paymentMean.minAmount || this.balance.amount > this.paymentMean.maxAmount);
    },
    outOfSddRange () {
      // TODO : remove this.paymentMean.minAmount && this.paymentMean.maxAmount from the tests when those parameters will be mandatory for SDD
      return this.paymentMean !== undefined && this.paymentMean.type === this.paymentMeans.SEPA_DD.value && this.paymentMean.minAmount && this.paymentMean.maxAmount &&
          (this.balance.amount < this.paymentMean.minAmount || this.balance.amount > this.paymentMean.maxAmount);
    },
    shouldDisplayHighlightedPis () {
      return this.isPisAvailable && !this.paymentWithCreditNotesOnly && this.availablePaymentMeans.length > 1;
    },
    noPaymentMeansForCreditor () {
      return !this.paymentMeansLoading && (!this.availablePaymentMeans || this.availablePaymentMeans.length === 0);
    },
    violatingPaymentMessage () {
      let messageLabel = null;
      if (this.noPaymentMeansForCreditor) {
        messageLabel = 'ERROR_NO_PAYMENT_MEANS_FOR_CREDITOR';
      } else if (this.negativePaymentAmount) {
        messageLabel = 'ERROR_NEGATIVE_PAYMENT_AMOUNT';
      } else if (this.outOfCreditCardRange) {
        messageLabel = 'ERROR_OUT_OF_CREDIT_CARD_RANGE';
      } else if (this.outOfPisRange) {
        messageLabel = 'ERROR_OUT_OF_PIS_RANGE';
      } else if (this.outOfSddRange) {
        messageLabel = 'ERROR_OUT_OF_SDD_RANGE';
      }
      return messageLabel;
    },
    getMinAmount () {
      return this.paymentMean.minAmount;
    },
    getMaxAmount () {
      return this.paymentMean.maxAmount;
    }
  },
  data () {
    return {
      creditNotePaymentMean: undefined,
      availablePaymentMeans: [],
      pisFilteredPaymentMeans: [],
      hideDetails: true,
      isPisAvailable: undefined,
      isSddAvailable: undefined,
      paymentMean: undefined,
      previousPaymentMean: undefined,
      pisPaymentMeans: [],
      paymentMeansLoading: true,
      mandatesLoading: true,
      onlyOnePaymentMean: false
    };
  },
  methods: {
    async validateSepa (date, umr) {
      this.$refs.sepaDialog.close();
      await this.$emit('pay', this.paymentMean, moment(date).toISOString(), umr);
    },
    async startPaymentProcess () {
      if (this.paymentMean.type === this.paymentMeans.SEPA_DD.value) {
        this.$refs.sepaDialog.open();
      } else {
        await this.$emit('pay', this.paymentMean);
      }
    },
    getDefaultCreditorPaymentMean () {
      const sortedPaymentMeansByPriority = [
        this.paymentMeans.PIS.value,
        this.paymentMeans.SEPA_DD.value,
        this.paymentMeans.CREDIT_CARD.value
      ];
      const creditorAuthorizedPaymentMeansByPriority = this.availablePaymentMeans
        .sort((first, second) =>
          sortedPaymentMeansByPriority.indexOf(first.type) - sortedPaymentMeansByPriority.indexOf(second.type));
      return creditorAuthorizedPaymentMeansByPriority[0];
    }
  },
  watch: {
    paymentWithCreditNotesOnly (value) {
      if (value) {
        this.paymentMean = this.creditNotePaymentMean;
      } else {
        this.paymentMean = this.previousPaymentMean;
      }
    },
    paymentMean () {
      if (this.paymentMean.type !== this.paymentMeans.CREDIT_NOTE.value) {
        this.previousPaymentMean = this.paymentMean;
      }
    },
    async creditor () {
      try {
        const { data } = await paymentMeanService.getEnabledPaymentMeans(this.creditor.id);
        this.creditNotePaymentMean = data.find(({ type }) => type === this.paymentMeans.CREDIT_NOTE.value);
        this.availablePaymentMeans = data.filter(paymentMean => paymentMean.type !== this.paymentMeans.CREDIT_NOTE.value &&
          (!this.oneOffMode || (this.oneOffMode && !(paymentMean.minAmount && paymentMean.maxAmount &&
            (this.balance.amount < paymentMean.minAmount || this.balance.amount > paymentMean.maxAmount)))));
        this.paymentMean = this.getDefaultCreditorPaymentMean();
        if (this.availablePaymentMeans.length === 1 && !this.paymentWithCreditNotesOnly) {
          this.onlyOnePaymentMean = true;
        }
        this.isPisAvailable = this.availablePaymentMeans.map(({ type }) => type).includes(this.paymentMeans.PIS.value);
        this.isSddAvailable = this.availablePaymentMeans.map(({ type }) => type).includes(this.paymentMeans.SEPA_DD.value);
        if (this.isSddAvailable) {
          this.mandatesLoading = true;
          try {
            await sepaMandateService.getMandatesByBillingAccountId(this.debtor.billingAccountId);
          } catch (err) {
            this.mandatesLoading = false;
          }
        }
        if (this.isPisAvailable && !this.paymentWithCreditNotesOnly) {
          this.pisPaymentMeans = this.availablePaymentMeans.filter(({ type }) => type === this.paymentMeans.PIS.value);
          if (this.availablePaymentMeans.length > 1) {
            this.pisFilteredPaymentMeans = this.availablePaymentMeans.filter(({ type }) => type !== this.paymentMeans.PIS.value);
          }
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.paymentMeansLoading = false;
      }
    }
  }
};
</script>

<style>
.custom-overline {
  color: rgba(0, 0, 0, 0.6);
  line-height: inherit !important;
}

.recommended-mean {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8125em;
}

.warning-mean {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.7em;
  font-style: italic;
}

.v-input-like {
  margin-top: 16px;
  padding-top: 4px;
}

.align-items-start {
  align-items: start;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-right-8 {
  margin-right: 8px;
}
</style>