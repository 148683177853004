<template>
  <div v-if="debtor">
    <v-data-table
      light
      hide-default-footer
      :headers="computedHeaders"
      :items="invoices"
      disable-pagination
      :loading="loading"
    >

      <template v-slot:item.number="{ item }">
        <span class="overline" v-if="!oneOffMode || item.pdfFilename === undefined">{{ item.number }}</span>
        <InvoiceNumberWithDownloadLink v-else :invoice="item" :oneOffMode="oneOffMode"></InvoiceNumberWithDownloadLink>
      </template>

      <template v-slot:item.issueDate="{ item }">
        <span>{{ item.issueDate | date }}</span>
      </template>

      <template v-slot:item.dueDate="{ item }">
        <span>{{ item.dueDate | date }}</span>
        <span v-if="!readonly && isBeforeToday(item.dueDate)">
          <br/>
          <v-icon color="red" small>mdi-clock-alert-outline</v-icon>
          (+{{ `${ daysSince(item.dueDate) }${ $t('DAY_FIRST_LETTER') }` }})
        </span>
      </template>

      <template v-slot:item.outstandingAmount="{ item }">
        <BoldAndRelativeAmount
          :amount="item.temporaryOutstandingAmount"
          :isInvoice="item.isInvoice"
          :currency="item.currency"
        />
      </template>

      <template v-slot:item.paymentAmount="{ item }">
        <v-text-field
          v-if="!oneOffMode"
          class="mt-4"
          v-model="item.balance.amount"
          type="number"
          dense
          :label="$t('AMOUNT')"
          :suffix="$options.filters.currency(item.currency)"
          outlined
          :rules="[rules.positive, rules.max(item.temporaryOutstandingAmount), rules.maxDecimals(2)]"
          append-icon="mdi-restore"
          @click:append="item.balance.amount = item.temporaryOutstandingAmount"
          maxlength="5"
        ></v-text-field>
        <strong
          v-else
        >{{ item.temporaryOutstandingAmount | currency(item.currency) }}</strong>
      </template>

      <template v-slot:item.initialAmount="{ item }">
        <strong class="black--text">
          {{ item.amountWithTax | currency(item.currency) }}
        </strong>
      </template>

      <template v-slot:item.paidAmount="{ item }">
        <BoldAndRelativeAmount
        :amount="item.balance.amount"
        :isInvoice="item.isInvoice"
        :currency="item.currency"
      />
      </template>

      <template v-slot:item.delete="{ item }">
        <DeleteItemButton :item="item" @delete-item="deleteItem(item)"></DeleteItemButton>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import numberMixin from '@/mixins/numberMixin';
import dateMixin from '@/mixins/dateMixin';
import DeleteItemButton from '@/components/debtor/checkout/DeleteItemButton';
import BoldAndRelativeAmount from '@/components/common/BoldAndRelativeAmount';
import InvoiceNumberWithDownloadLink from '@/components/common/buttons/InvoiceNumberWithDownloadLink';
import { mapState } from 'vuex';

export default {
  mixins: [numberMixin, dateMixin],
  components: {
    DeleteItemButton,
    BoldAndRelativeAmount,
    InvoiceNumberWithDownloadLink
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    oneOffMode: {
      type: Boolean,
      default: false
    },
    debtor: {
      type: Object,
      default () {
        return this.selectedDebtorAccount;
      }
    }
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('INVOICE_NUMBER'),
          value: 'number'
        },
        {
          text: this.$t('INVOICE_ISSUE_DATE'),
          value: 'issueDate',
          align: 'center',
          hidden: () => this.$vuetify.breakpoint.mobile
        },
        {
          text: this.$t('INVOICE_DUE_DATE'),
          value: 'dueDate',
          align: 'center'
        },
        {
          text: this.$t('INVOICE_OUTSTANDING_AMOUNT'),
          value: 'outstandingAmount',
          hidden: () => this.readonly,
          align: 'end'
        },
        {
          text: this.$t('INVOICE_PAYMENT_AMOUNT'),
          value: 'paymentAmount',
          sortable: false,
          width: 185,
          hidden: () => this.readonly
        },
        {
          text: this.$t('INVOICE_INITIAL_AMOUNT'),
          value: 'initialAmount',
          align: 'end'
        },
        {
          text: this.$t('PAID_AMOUNT'),
          value: 'paidAmount',
          hidden: () => !this.readonly
        },
        {
          value: 'delete',
          sortable: false,
          hidden: () => this.readonly || this.oneOffMode
        }
      ],
      rules: {
        positive: v => (v && v > 0) || this.$t('RULES_POSITIVE_AMOUNT'),
        max: amount => (v => v && v <= amount) || this.$t('RULES_MUST_NOT_EXCEED_AMOUNT', { amount }),
        maxDecimals: max => v => this.countDecimals(v) <= max || this.$t('RULES_MUST_NOT_EXCEED_DECIMALS', { decimals: max })
      }
    };
  },
  computed: {
    computedHeaders () {
      return this.headers
        .filter(header => !header.hidden || !header.hidden());
    },
    ...mapState(['selectedDebtorAccount']),
    ...mapState({
      invoices: state => state.basket.invoices
    })
  },
  methods: {
    deleteItem (item) {
      this.invoices.splice(this.invoices.indexOf(item), 1);
    }
  }
};
</script>