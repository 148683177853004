<template>
  <v-container>
    <v-row class="my-1">
      <v-btn :to="{name: 'DebtorToPayInvoices'}" color="primary">
        <v-icon left>mdi-arrow-left-bold</v-icon>
        <strong>{{ $t('BACK') }}</strong>
      </v-btn>
    </v-row>
    <div class="headline mb-5">
      {{ $t('TRANSACTION') }}
    </div>

    <v-skeleton-loader
      class="mx-auto"
      type="card-heading"
      v-if="attrs.loading"
    ></v-skeleton-loader>

    <v-alert
      v-else
      :type="successful ? 'success' : 'error'"
      tile
      dismissible
      outlined
      border="left"
    >
      <i18n :path="successful ? 'TRANSACTION_MESSAGE_SUCCESS' : 'TRANSACTION_MESSAGE_ERROR'" tag="span">
        <template v-slot:date v-if="transactionDate">
          <strong>
            {{ transactionDate | dateTime }}
          </strong>
        </template>
      </i18n>
    </v-alert>

    <v-form v-model="attrs.valid" ref="form">
      <v-row>
        <v-col cols="12" md="9" class="order-2 order-md-1">
          <InvoiceTable
            :readonly="successful"
            :loading="attrs.loading"
          ></InvoiceTable>
        </v-col>

        <v-col cols="12" md="3" class="order-1 order-md-2">
          <CheckoutSummary
            :readonly="successful"
            v-bind="attrs"
            v-on="on"
          ></CheckoutSummary>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import basketService from '@/services/basketService';
import paymentOperationService from '@/services/paymentOperationService';
import userService from '@/services/userService';

import dateMixin from '@/mixins/dateMixin';
import { SuccessfulPaymentStatusesEnum } from '../../enums/paymentStatusesEnum';

import InvoiceTable from '@/components/debtor/checkout/InvoiceTable';
import CheckoutSummary from '@/components/debtor/checkout/CheckoutSummary';

import { mapState } from 'vuex';

export default {
  components: {
    InvoiceTable,
    CheckoutSummary
  },
  mixins: [dateMixin],
  data () {
    return {
      attrs: {
        loading: false,
        paymentLoading: false,
        creditor: undefined,
        debtor: undefined,
        valid: false
      },
      on: {
        pay: this.startPaymentProcess
      },
      successful: undefined,
      transactionDate: undefined
    };
  },

  computed: {
    ...mapState({
      basket: state => state.basket
    }),
    ...mapState(['selectedDebtorAccount', 'creditor'])
  },

  async beforeMount () {
    try {
      this.attrs.loading = true;
      this.$store.dispatch('clearBasket');
      const { data } = await paymentOperationService.getPaymentOperationByTransactionReference(this.$route.params.id);

      const basket = basketService.createBasketFromPaymentOperation(data, this.creditor.id);
      this.$store.dispatch('setBasket', basket);

      this.successful = Object.values(SuccessfulPaymentStatusesEnum).includes(data.status);
      this.transactionDate = data.transactionDate;

      const creditorAccount = await userService.getCreditorAccount(this.basket.creditorId);
      this.attrs.creditor = creditorAccount.data;
    } catch (err) {
      this.handleError(err);
    } finally {
      this.attrs.loading = false;
    }
  },

  mounted () {
    return this.$refs.form.validate();
  },

  methods: {
    async startPaymentProcess (paymentMean, directDebitDate, umr) {
      this.attrs.paymentLoading = true;
      try {
        const { data } = await paymentOperationService.createPaymentOperation({
          basket: this.basket,
          creditorId: this.attrs.creditor.id,
          debtorId: this.selectedDebtorAccount.id,
          paymentMean,
          directDebitDate,
          umr,
          languageCode: this.$t('LANG_ID')
        });
        if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.attrs.paymentLoading = false;
      }
    }
  },

  watch: {
    'basket.invoices': {
      deep: true,
      handler () {
        if (this.$refs.form.validate()) {
          const balance = basketService.computeBasketBalance(this.basket);
          this.basket.balance = balance;
          return this.$store.dispatch('setBasket', this.basket);
        }
      }
    }
  }
};
</script>
