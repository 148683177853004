<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          icon
          color="primary"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline">
          {{ $t('DELETE_ITEM_TITLE', { item: item.number }) }}
        </v-card-title>

        <v-card-text>
          {{ $t('DELETE_ITEM_QUESTION', { item: item.number }) }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="dialog = false">{{ $t('ACTION_CANCEL') }}</v-btn>
          <v-btn text color="primary" @click="deleteItem">{{ $t('DELETE') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: false
    };
  },
  methods: {
    deleteItem () {
      this.$emit('delete-item');
      this.dialog = false;
    }
  }
};
</script>