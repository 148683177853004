<template>
  <v-dialog v-model="show"
            width="550"
            persistent>
    <v-card>
      <v-card-title>
        {{ $t('DIRECT_DEBIT') }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="sepaValid">
          <v-autocomplete
            v-if="mandates.length > 0"
            v-model="selectedMandate"
            item-text="umr"
            item-value="umr"
            return-object
            :label="$t('DIRECT_DEBIT_ACCOUNT')"
            :rules="[rules.requiredFieldRules]"
            :items="mandates"
            :loading="mandatesLoading"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.umr }}</v-list-item-title>
                <v-list-item-subtitle>{{ data.item.iban }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <div v-else align="center" class="mb-2">{{ $t('INTRODUCTION_CAPTION') }}</div>
          <div class="d-flex justify-center black--text">
            <div v-if="mandates.length > 0"  class="mb-2 mr-4">{{ $t('DISPLAY_CAPTION') }}</div>
            <div align="center" class="mb-4">
            <v-btn
            color="primary"
            justify="space-around"
            class="ma-0"
            @click="createMandate"
            >
            <v-icon>
              mdi-plus
            </v-icon>
             {{ $t('BUTTON_ADD_ACCOUNT') }}
            </v-btn>
            </div>
            </div>
          <v-menu
            ref="sepaDateMenu"
            v-model="sepaDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedDate"
                :label="$t('DEBIT_DATE') + ' *'"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[rules.requiredFieldRules]"
                @blur="directDebitDate = parseDate(directDebitDate)"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="directDebitDate"
              :min="minimumDebitDate"
              :max="maximumDebitDate"
              @input="sepaDateMenu = false"
            ></v-date-picker>
          </v-menu>
          <div class="black--text">
            {{ $t('AMOUNT') }} :
            <strong>{{ balance.amount | currency(debtor.currency) }}</strong>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row
          justify="space-around" class="ma-0">
          <v-btn
            color="error"
            @click="close"
          >
            {{ $t('ACTION_CANCEL') }}
          </v-btn>
          <v-btn
            :disabled="!sepaValid || sepaLoading"
            color="primary"
            @click="onValidate(directDebitDate, selectedMandate.umr)"
          >
            {{ $t('ACTION_VALIDATE') }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment-timezone';
import numberMixin from '@/mixins/numberMixin';
import ruleMixin from '@/mixins/ruleMixin';
import dateMixin from '@/mixins/dateMixin';
import { mapState } from 'vuex';
import sepaMandateService from '@/services/sepaMandateService';
import { RedirectUrlEnum } from '../../../enums/redirectUrlEnum';

export default {
  name: 'SepaPaymentDialog',
  mixins: [numberMixin, ruleMixin, dateMixin],
  props: {
    onValidate: Function,
    debtor: {
      type: Object,
      default () {
        return this.selectedDebtorAccount;
      }
    }
  },
  data () {
    return {
      sepaValid: false,
      sepaDateMenu: false,
      minimumDebitDate: this.parseDate(moment()),
      directDebitDate: this.parseDate(moment().add(1, 'days')),
      mandates: [],
      mandatesLoading: true,
      selectedMandate: null,
      sepaLoading: false,
      show: false
    };
  },
  async beforeMount () {
    this.mandatesLoading = true;
    try {
      const { data } = await sepaMandateService.getMandatesByBillingAccountId(this.debtor.billingAccountId);
      this.mandates = data;
    } catch (err) {
      this.displayErrorSnackbar(this.$t('PAYMENT_SEPA_UNAVAILABLE'));
    } finally {
      this.mandatesLoading = false;
    }
  },
  computed: {
    ...mapState(['selectedDebtorAccount']),
    ...mapState({
      basket: state => state.basket,
      balance: state => state.basket.balance,
      invoices: state => state.basket.invoices
    }),
    formattedDate () {
      return moment(this.directDebitDate).format('DD/MM/YYYY');
    },
    maximumDebitDate () {
      // Direct debit date selection can be done between :
      //
      // - Today
      // - The latest date from :
      //      - 10 days from now
      //      - the earliest due dates of the invoices in the payment selection
      const earliestInvoicesDueDate = moment(Math.min(...this.invoices.map(invoice => moment(invoice.dueDate))));
      const defaultRangeEndDebitDate = moment().add(10, 'days');

      return earliestInvoicesDueDate.isBefore(defaultRangeEndDebitDate) ? defaultRangeEndDebitDate.toISOString() : earliestInvoicesDueDate.toISOString();
    }
  },
  methods: {
    close () {
      this.show = false;
    },
    open () {
      this.show = true;
    },
    async createMandate () {
      try {
        const { data } = await sepaMandateService.createMandate(this.debtor.billingAccountId, RedirectUrlEnum.DEBTOR_CHECKOUT);
        if (data.redirectionUrl) {
          window.location.href = data.redirectionUrl;
        }
      } catch (err) {
        this.handleError(err);
      }
    }
  }
};
</script>